// import { MainImage } from "gatsby-plugin-image";
import React from "react";
import "./multipleRowsInfiniteSlider.scss"
import { Box } from "@mui/system";

const COLORS = [ '#bbf7d0', '#99f6e4', '#bfdbfe', '#ddd6fe', '#f5d0fe', '#fed7aa', '#fee2e2', 'var(--color-primary-main)', ];
const TAGS = [ 'HTML', 'CSS', 'JavaScript', 'Typescript', 'Theme-UI', 'React', 'Material UI', 'Gatsby', 'UI/UX', 'SVG', 'animation', 'webdev' ];
const DURATION = 25000;
const ROWS = 2;
const TAGS_PER_ROW = 10;

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr) => [ ...arr ].sort(() => .5 - Math.random());

const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
    return (
        <div className='loop-slider' style={{
            '--duration': `${duration}ms`,
            '--direction': reverse ? 'reverse' : 'normal',
            '--loop': reverse ? "loopRev" : "loop"
        }}>
            <div className='inner'>
                {children}
            </div>
        </div>
    );
};

const Tag = ({ text, color }) => (
    <Box
        className='tag'
        sx={{
            '--color': 'var(--color-primary-text2)',
            borderRadius: '50%',
            fontSize: 'medium', // [ "smaller", "small" ],
        }}><span>#</span> {text}</Box>
);

const MultipleRowsInfiniteSlider = () => (
    <div className='wrap'
        style={{
            // backgroundColor: 'var(--color-primary-main)',
            backgroundColor: 'inherit',
        }}
    >
        <div className='tag-list'>
            {[ ...new Array(ROWS) ].map((_, i) => {
                const col = COLORS[ i ]
                return (
                    <InfiniteLoopSlider key={i}
                        // duration={random(DURATION - 5000, DURATION + 5000)} 
                        duration={DURATION}
                        reverse={i % 2}>
                        {shuffle(TAGS).slice(0, TAGS_PER_ROW).map((tag, j) => (
                            <Tag text={tag} color={COLORS[ j ]} key={tag} />
                        ))}
                    </InfiniteLoopSlider>
                )
            })}
            <div className='fade' />
        </div>
    </div>
);

export default MultipleRowsInfiniteSlider